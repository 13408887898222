import React, { useEffect, useState } from 'react';

import axios from 'axios';
import { useAuthProvider } from 'leeloo-react/component/hook/useAuthProvider';
import { Button, Modal } from 'react-bootstrap';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';

import './popup.css';
import 'react-toastify/dist/ReactToastify.css';
import PopupInscrit from './PopupInscrit';
import Hn from '../common/Hn';

export default function Popupinscrire({
  data,
  handleModalShow,
  handleModalClose,
  options,
  uri,
}) {
  let toastId = null;
  const [selectedOption, setSelectedOption] = useState(null);
  const [recap, setRecap] = useState(false);
  const [inscrits, setInscrits] = useState([]);
  const [collaborators, setCollaborators] = useState(null);
  const [selectedCollaborateur, setSelectedCollaborateur] = useState(null);
  const [inscriptionCollaborateur, setInscriptionCollaborateur] = useState([]);
  const { authData } = useAuthProvider();
  axios.defaults.headers.common = { Authorization: `Bearer ${authData.token}` };

  const customStyles = {
    control: (base, state) => ({
      ...base,
      fontSize: '16px',
      fontWeight: state.isSelected ? '600' : '400',
      backgroundColor: '#3A4048',
      color: 'white',
      border: 'none',
      borderRadius: 'none',
    }),
    dropdownIndicator: (base) => ({
      color: '#006FFF',
      marginRight: '10px',
    }),
    menu: (base) => ({
      backgroundColor: '#212730',
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected ? '#1E2E45' : '#212730',
      fontWeight: state.isSelected ? '600' : '400',
      borderBottom: '1px solid #3A4048',
      color: 'white',
      margin: '5px 14px 5px 10px',
    }),
    singleValue: (base, state) => ({
      ...base,
      color: 'white',
      backgroundColor: '#1E2E45',
      fontWeight: state.isSelected ? '600' : '400',
      margin: '6px 10px 5px 10px',
      padding: '5px 14px 5px 10px',
    }),
    multiValue: (base, state) => ({
      ...base,
      color: 'white',
      backgroundColor: '#1E2E45',
      fontWeight: state.isSelected ? '600' : '400',
      margin: '6px 10px 5px 10px',
      padding: '5px 14px 5px 10px',
    }),
    multiValueLabel: (base) => ({
      color: 'white',
    }),
    multiValueRemove: (base) => ({
      color: '#006FFF',
      fontSize: '16px',
      height: '20px',
      width: '20px',
    }),
    input: (base, state) => ({
      ...base,
      color: 'white',
    }),
  };

  // R2cupération de la liste des collaborateurs lié à l'utilisateur
  function getCollaborators() {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_BASE_URL}/participant/collaborators/${authData.id}`,
    })
      .then((response) => {
        setCollaborators(response.data);
      });
  }

  useEffect(() => {
    getCollaborators();
  }, []);

  const handleSubmit = () => {
    const opCollaborateur = [];
    selectedCollaborateur.map((opc) => {
      opCollaborateur.push(opc.value);
    });

    const dataSend = {
      participants: opCollaborateur,
      session: selectedOption.value ?? null,
    };

    axios
      .post(`${uri}/session/inscription`, dataSend)
      .then((result) => {
        if (result && result.data && result.data.participant_already_registered_in_another_session) {
          setInscrits(result.data.participant_already_registered_in_another_session);
        } else {
          setInscrits([]);
        }
        setRecap(true);
        setInscriptionCollaborateur(collaborators.filter((el) => result.data.participants.includes(el.id)));
      })
      .catch((err) => {
        displayToast('Cerror', 'Une erreur s\'est produite');
      });
  };

  const handleClose = () => {
    resetModal();
    handleModalClose();
  };

  const resetModal = () => {
    setSelectedOption(null);
    setRecap(false);
    setInscrits([]);
    setSelectedCollaborateur(null);
    setInscriptionCollaborateur([]);
  };

  function displayToast(type, msg) {
    if (!toast.isActive(toastId)) {
      toastId = toast(msg, {
        className: type,
        closeOnClick: false,
        toastId: 'my_toast',
        autoClose: true,
        closeButton: false,
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      console.log('Toast already active');
    }
  }

  return (
    <Modal
      show={handleModalShow}
      onHide={handleClose}
      size="lg"
      className="special-width-popup popup"
      centered
      contentClassName="bg-black"
    >
      <Modal.Header closeButton>
        <Modal.Title className="p-3 nopad">
          {!recap
            ? (
              <>
                <Hn title={data.title} level="h3" plus={false} />
                {' '}
                {data.cat}
              </>
            )
            : (
              inscriptionCollaborateur.length > 0
                  ? (<h3>Félicitations !</h3>)
                  : (inscrits.length > 0 && (<h3>Inscription impossible !</h3>))
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ToastContainer />
        <div className="row px-3 nopad ">
          {!recap
            ? (
              <>
                <div className="col-md">
                  <Hn title="Qui ?" level="h5" plus={false} className="label-popup" />
                  <Select
                    styles={customStyles}
                    onChange={setSelectedCollaborateur}
                    className="themeSelect"
                    options={collaborators ? collaborators.map((collaborator) => ({
                      value: collaborator.id,
                      label: collaborator.fullname,
                    })) : {}}
                    isMulti
                    placeholder="Sélectionner un ou des apprenant(s)"
                    noOptionsMessage={() => 'Aucun résultat'}
                  />
                </div>
                <div className="col-md">
                  <Hn
                    title="Quand ?"
                    level="h5"
                    plus={false}
                    className="label-popup"
                  />
                  <Select
                    styles={customStyles}
                    // onChange={handleChange}
                    onChange={setSelectedOption}
                    className="themeSelect"
                    options={options}
                    placeholder="Sélectionner une date"
                    noOptionsMessage={() => 'Aucun résultat'}
                  />
                </div>
              </>
            )
            : (
              <PopupInscrit
                collaborators={inscriptionCollaborateur}
                trainingTitle={data.title}
                sessionLabel={selectedOption.label}
                dejaInscrits={inscrits}
              />
            )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        {!recap
          ? (
            <Button
              variant="primary"
              onClick={handleSubmit}
            >
              Valider
            </Button>
          )
          : (
            <Button
              variant="primary"
              onClick={handleClose}
            >
              Fermer
            </Button>
          )}
      </Modal.Footer>
    </Modal>
  );
}
